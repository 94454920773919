import { scroll } from 'motion';

export function timeline() {
  initTimeline();
  window.swup.hooks.on('content:replace', initTimeline);
}

const initTimeline = () => {
  const container = document.querySelector('[data-timeline]') as HTMLElement;
  if (!container) return;

  const items = container.querySelectorAll('li');
  const images = container.querySelectorAll('figure');
  const count = items.length;

  scroll(
    (progress: number) => {
      // set css var
      (container.firstElementChild as HTMLElement).style.setProperty('--progress', `${progress}`);

      // set active
      const active = Math.floor(progress * count);

      items.forEach((item, index) => {
        if (index === active || (progress >= 1 && index === items.length - 1)) {
          item.setAttribute('data-active', '');
        } else {
          item.removeAttribute('data-active');
        }
      });

      images.forEach((image, index) => {
        if (index === active || (progress >= 1 && index === items.length - 1)) {
          image.setAttribute('data-active', '');
        } else {
          image.removeAttribute('data-active');
        }
      });
    },
    {
      target: container,
    },
  );
};
