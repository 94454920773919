import { a11y } from './a11y';
import { accordion } from './accordion';
import { anchors } from './anchors';
import { ctaAnimation } from './cta-animation';
// import { fadeAnimation } from './fade-animation';
import { filterAndSort } from './filter-and-sort';
import { gallery } from './gallery';
import { heroAnimation } from './hero-animation';
import { menu } from './menu';
import { organizationsCarousel } from './organizations-carousel';
import { swup } from './swup';
import { tags } from './tags';
import { timeline } from './timeline';

const initApp = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Environment:', process.env.NODE_ENV);
  }

  swup();
  menu();
  heroAnimation();
  anchors();
  filterAndSort();
  gallery();
  ctaAnimation();
  organizationsCarousel();
  accordion();
  timeline();
  tags();
  a11y();
  // fadeAnimation();
};

// Simple DOM ready check
const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

rdy(initApp);
