import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export function organizationsCarousel() {
  initCarousel();
  window.swup.hooks.on('content:replace', initCarousel);
}

const initCarousel = () => {
  // Find all organization carousel containers
  const containers = document.querySelectorAll('.splide[data-organizations]') as NodeListOf<HTMLElement>;
  if (!containers.length) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');

  containers.forEach((container) => {
    // Initialize Splide carousel with responsive config
    const splide = new Splide(container, {
      mediaQuery: 'min',
      perPage: 1,
      perMove: 1,
      gap: '24px',
      type: 'loop',
      arrows: true,
      pagination: false,
      speed: stopAnimations ? 0 : 400,
      // Responsive breakpoints for different screen sizes
      breakpoints: {
        768: {
          mediaQuery: 'min',
          perPage: 2, // Show 2 slides on tablet
        },
        1024: {
          mediaQuery: 'min',
          perPage: 3, // Show 3 slides on desktop
        },
      },
      // autoscroll
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
      },
    });

    if (stopAnimations) {
      splide.mount();
    } else {
      splide.mount({
        AutoScroll,
      });

      // pause on interaction
      splide.on('move', () => {
        splide?.Components?.AutoScroll?.pause();
      });
    }

    // Make entire slide clickable if it contains a link
    container.querySelectorAll('.splide__slide:has(a)').forEach((slide) => {
      slide.addEventListener('click', () => {
        window.open(slide.querySelector('a')?.href, '_blank');
      });
    });
  });
};
