import lottie from 'lottie-web';
import { inView } from 'motion';

export function heroAnimation() {
  initHeroAnimation();

  // Reinitialize animations only on page changes, not same-page navigation
  window.swup.hooks.on('content:replace', (e) => {
    // Normalize URLs to include domain for comparison
    let from = e.from.url;
    if (!from.includes(window.location.host)) {
      from = `https://${window.location.host}${from}`;
    }
    const fromUrl = new URL(from);

    let to = e.to.url;
    if (!to.includes(window.location.host)) {
      to = `https://${window.location.host}${to}`;
    }
    const toUrl = new URL(to);

    // Only reinit if navigating to a different page
    if (fromUrl.pathname !== toUrl.pathname) {
      initHeroAnimation();
    }
  });
}

const initHeroAnimation = () => {
  const container = document.querySelector('[data-animation]');
  if (!container) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');
  if (stopAnimations) return;

  // Track viewport size for responsive animations
  const breakpoint = window.matchMedia('(min-width: 768px)');

  let animation = createAnimation(container, breakpoint);

  // Recreate animation when viewport crosses breakpoint
  breakpoint.addEventListener('change', () => {
    animation.destroy();
    animation = createAnimation(container, breakpoint);
  });
};

const createAnimation = (container: Element, breakpoint: MediaQueryList) => {
  // Select random animation from 1-9
  const random = Math.floor(Math.random() * 9) + 1;
  const file = breakpoint.matches ? `desktop${random}` : `mobile${random}`;

  // Special case: flip animation #2 horizontally on desktop
  if (random === 2) {
    container.classList.add('scale-x-[-1]');
  }

  // Initialize Lottie animation
  const animation = lottie.loadAnimation({
    container,
    path: `/wp-content/themes/thedepot-theme/app/assets/lottie/${file}.json`,
    autoplay: false,
    loop: false,
  });

  animation.setSubframe(false);
  animation.play();

  inView(container, () => {
    if (animation.isPaused) {
      animation.goToAndPlay(0, true);
    }
    // enable replay
    return () => {};
  });

  return animation;
};
