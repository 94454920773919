import Splide from '@splidejs/splide';

export function gallery() {
  initCarousel();
  window.swup.hooks.on('content:replace', initCarousel);
}

const initCarousel = () => {
  // Find all gallery carousel containers
  const containers = document.querySelectorAll('.splide[data-gallery]') as NodeListOf<HTMLElement>;
  if (!containers.length) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');

  // Track viewport size for responsive behavior
  const mediaQuery = window.matchMedia('(min-width: 768px)');

  containers.forEach((container) => {
    const list = container.querySelector('.splide__list') as HTMLElement;
    // Find date slide if it exists (slide containing a paragraph)
    const dateSlide = container.querySelector('.splide__slide:has(p)') as HTMLElement;

    // Handle date slide visibility based on screen size
    if (dateSlide) {
      addOrRemoveDateSlide(list, dateSlide, mediaQuery);
    }

    // Initialize Splide carousel with config
    const splide = new Splide(container, {
      speed: stopAnimations ? 0 : 400,
      perPage: 1,
      perMove: 1,
      gap: '0px',
      type: 'loop',
      arrows: true,
      pagination: false,
    });

    splide.mount();

    // Update date slide visibility when screen size changes
    if (dateSlide) {
      mediaQuery.addEventListener('change', () => addOrRemoveDateSlide(list, dateSlide, mediaQuery));
    }
  });
};

// Helper function to manage date slide visibility
const addOrRemoveDateSlide = (list: HTMLElement, dateSlide: HTMLElement, mediaQuery: MediaQueryList) => {
  if (!mediaQuery.matches) {
    // Remove date slide on mobile
    dateSlide.remove();
  } else {
    // Insert date slide as second item on desktop
    const secondChild = list.children[1];
    if (secondChild) {
      list.insertBefore(dateSlide, secondChild);
    } else {
      list.appendChild(dateSlide);
    }
  }
};
