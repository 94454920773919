import { press } from 'motion';

export function a11y() {
  initA11y();
}

const initA11y = () => {
  const menuButton = document.querySelector('#a11y-menu-button');
  if (!menuButton) return;

  const menu = document.querySelector('#a11y-menu');
  if (!menu) return;

  menuButton.addEventListener('click', () => {
    if (menuButton.getAttribute('aria-expanded') === 'true') {
      menuButton.setAttribute('aria-expanded', 'false');
      menu.setAttribute('aria-hidden', 'false');
    } else {
      menuButton.setAttribute('aria-expanded', 'true');
      menu.setAttribute('aria-hidden', 'true');
    }
  });

  const buttons = menu.querySelectorAll('[data-type]');

  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      const type = `${button.getAttribute('data-type')}`;

      const pressed = button.getAttribute('aria-pressed') === 'true' ? 'false' : 'true';
      button.setAttribute('aria-pressed', pressed);

      if (type === 'reset') {
        document.documentElement.classList.remove('bigger-text');
        document.documentElement.classList.remove('highlight-links');
        document.documentElement.classList.remove('invert-colors');
        document.documentElement.classList.remove('max-contrast');
        document.documentElement.classList.remove('stop-animations');
        buttons.forEach((button) => {
          button.setAttribute('aria-pressed', 'false');
        });
        // delete a11y cookie
        document.cookie = `a11y=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        return;
      }

      const activeTypes = [...buttons]
        .map((button) => (button.getAttribute('aria-pressed') === 'true' ? button.getAttribute('data-type') : false))
        .filter(Boolean)
        .join(' ');

      if (pressed === 'true') {
        document.documentElement.classList.add(type);

        const date = new Date();
        date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

        document.cookie = `a11y=${activeTypes}; expires=${date.toUTCString()}; path=/`;

        window.swup.scrollTo = () => {};
        swup.navigate(window.location.href, {
          history: 'replace',
          animate: false,
        });
      } else {
        document.documentElement.classList.remove(type);

        if (activeTypes.length > 0) {
          // update a11y cookie
          const date = new Date();
          date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

          document.cookie = `a11y=${activeTypes}; expires=${date.toUTCString()}; path=/`;
        } else {
          // delete a11y cookie
          document.cookie = `a11y=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        }
      }
    });
  });
};
