export function menu() {
  const menu = document.querySelector('#menu') as HTMLDivElement;
  const button = document.querySelector('.hamburger');
  // const closeButton = document.querySelector('.hamburger.close');

  if (!menu) return;

  const toggleMenu = () => {
    menu.toggleAttribute('data-open');
    button?.toggleAttribute('data-open');
  };

  const closeMenu = () => {
    menu.removeAttribute('data-open');
    button?.removeAttribute('data-open');
  };

  // Use passive event listeners for better scroll performance
  button?.addEventListener('click', toggleMenu, { passive: true });
  window.swup.hooks.on('content:replace', closeMenu);

  // on click outside
  document.addEventListener('click', (event) => {
    const target = event.target as Node;
    // Ignore clicks on the menu itself and the open button
    if (!menu.contains(target) && !button?.contains(target)) {
      closeMenu();
    }
  });
}
