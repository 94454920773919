/**
 * Sets up event & news filtering functionality with preloading
 */
export function filterAndSort() {
  const init = () => {
    initFilters();
    initMobileFilters();
    initSort();
  };

  init();
  window.swup.hooks.on('content:replace', init);
}

export const updateUrlAndNavigate = (modifier: (url: URL) => void): void => {
  const url = new URL(window.location.href);
  modifier(url);
  // prevent scroll to top
  window.swup.scrollTo = () => {};
  window.swup.navigate(url.toString(), {
    history: 'replace',
    animate: false,
  });
};

const initFilters = () => {
  const container = document.querySelector<HTMLElement>('[data-filters]');
  if (!container) return;

  const preloadedUrls = new Set<string>();

  // Handle filter changes
  container.addEventListener('change', (e) => {
    const radio = e.target as HTMLInputElement;
    if (radio.type !== 'radio') return;

    updateUrlAndNavigate((url) => {
      radio.value ? url.searchParams.set('cat', radio.value) : url.searchParams.delete('cat');
    });
  });

  // Handle preloading
  container.addEventListener('mouseover', (e) => {
    if (!window.swup.preload) return;

    const label = (e.target as HTMLElement).closest('label');
    if (!label) return;

    const radio = document.getElementById(label.htmlFor) as HTMLInputElement | null;
    if (!radio) return;

    const url = new URL(window.location.href);
    radio.value ? url.searchParams.set('cat', radio.value) : url.searchParams.delete('cat');
    const urlString = url.toString();

    if (preloadedUrls.has(urlString)) return;

    preloadedUrls.add(urlString);
    window.swup.preload(urlString).catch(() => {
      // Silently fail preload
    });
  });
};

const initMobileFilters = () => {
  const filter = document.querySelector<HTMLSelectElement>('select[name="filter"]');
  if (!filter) return;

  filter.addEventListener('change', (e) => {
    const value = (e.target as HTMLSelectElement).value;
    updateUrlAndNavigate((url) => {
      value === 'all' ? url.searchParams.delete('cat') : url.searchParams.set('cat', value);
    });
  });
};

const initSort = () => {
  const sort = document.querySelector<HTMLSelectElement>('select[name="sort"]');
  if (!sort) return;

  sort.addEventListener('change', (e) => {
    const value = (e.target as HTMLSelectElement).value;
    updateUrlAndNavigate((url) => {
      value === 'date' ? url.searchParams.delete('sort') : url.searchParams.set('sort', value);
    });
  });
};
