export function tags() {
  initTags();
  window.swup.hooks.on('content:replace', initTags);
}

const initTags = () => {
  const select = document.querySelector<HTMLSelectElement>('select[name="tags"]');
  if (!select) return;

  select.addEventListener('change', (e) => {
    const value = (e.target as HTMLSelectElement).value;

    if (!value) return;

    // url = data-type + ?cat=value
    const url = `/${select.getAttribute('data-type')}?cat=${value}`;

    window.swup.navigate(url);
  });
};
