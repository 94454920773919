export function accordion() {
  initAccordion();
  window.swup.hooks.on('content:replace', initAccordion);
}

const initAccordion = () => {
  // add aria to detail/summary
  const details = document.querySelectorAll('details');

  details.forEach((detail, index) => {
    const summary = detail.querySelector('summary');
    const content = detail.querySelector(':scope > *:not(summary)');

    if (summary && content) {
      // Set unique IDs
      const questionId = `question-${index}`;
      const answerId = `answer-${index}`;
      summary.setAttribute('id', questionId);
      content.setAttribute('id', answerId);

      // Add ARIA attributes to summary
      summary.setAttribute('role', 'button');
      summary.setAttribute('aria-expanded', detail.hasAttribute('open') ? 'true' : 'false');
      summary.setAttribute('aria-controls', answerId);

      // Add ARIA attributes to content
      content.setAttribute('role', 'region');
      content.setAttribute('aria-labelledby', questionId);

      // Add event listener to update aria-expanded
      detail.addEventListener('toggle', () => {
        summary.setAttribute('aria-expanded', detail.hasAttribute('open') ? 'true' : 'false');
      });
    }
  });
};
