export function anchors() {
  const init = () => {
    initSmoothScroll();
    initMobileLinks();
  };

  init();
  window.swup.hooks.on('content:replace', init);
}

const initSmoothScroll = () => {
  // Find all hash links (e.g., <a href="#section">)
  const anchorLinks = document.querySelectorAll('a[href^="#"]') as NodeListOf<HTMLAnchorElement>;

  if (!anchorLinks.length) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');

  // Add smooth scroll behavior to each anchor link
  anchorLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const target = document.querySelector(link.getAttribute('href') as string);
      if (target) {
        target.scrollIntoView({ behavior: stopAnimations ? 'auto' : 'smooth' });
      }
    });
  });
};

const initMobileLinks = () => {
  // Handle mobile dropdown navigation
  const links = document.querySelector<HTMLSelectElement>('select[name="links"]');
  if (!links) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');

  links.addEventListener('change', (e) => {
    const value = (e.target as HTMLSelectElement).value;

    if (!value) return;

    // Scroll to selected section
    const target = document.querySelector(`#${value}`);
    if (target) {
      target.scrollIntoView({ behavior: stopAnimations ? 'auto' : 'smooth' });
    }

    // Update URL hash without page reload
    const url = new URL(window.location.href);
    url.hash = value;
    window.history.pushState({}, '', url.toString());
  });
};
