import { animate, inView, scroll } from 'motion';

export function ctaAnimation() {
  initCTAAnimation();
  window.swup.hooks.on('content:replace', initCTAAnimation);
}

const initCTAAnimation = () => {
  const badge = document.querySelector('[data-badge]') as HTMLElement;
  if (!badge) return;

  const stopAnimations = !!document.documentElement.classList.contains('stop-animations');
  if (stopAnimations) return;

  // pop in / out in viewport
  inView(
    badge.parentElement as HTMLElement,
    () => {
      animate(badge, { opacity: 1, scale: 1 }, { delay: 0.15 });
      return () => {
        animate(badge, { opacity: 0, scale: 0 });
      };
    },
    {
      amount: 0.5,
    },
  );

  // slight parallax effect
  scroll(animate(badge, { y: [-24, 24] }), { target: badge });
};
